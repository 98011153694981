// /chat/queryChatRecord
//聊天页面
import request from '@/utils/request'

// 登录方法
export function queryChatRecord (data) {
  return request({
    url: `/chat/queryChatRecord?pageSize=${data.pageSize}&pageNum=${data.pageNum}`,
    headers: {
      isToken: true
    },
    method: 'get',
    data: data
  })
}
//发送内容
export function sendMessage(data) {
  return request({
    url: '/chat/sendMessage',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}

//落地页发送信息
export function sendMessageForLanding(data) {
  return request({
    url: '/chat/sendMessageForLanding',
    headers: {
      isToken: true
    },
    method: 'post',
    data: data
  })
}
